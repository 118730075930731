<template>
  <div class="app-container">
    <navBar :title="'报修详情'" @handleGoBack="handleGoBack"/>

    <div class="add-form">
      <van-form validate-first @failed="onFailed" @submit="onSubmit">
        <van-field
            v-model="formInline.customerName"
            label="客户名称"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.materialName"
            label="产品名称"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.contactPerson"
            label="联系人"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.contactPhone"
            label="联系电话"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.address"
            label="联系地址"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.occurTime"
            label="问题发生时间"
            placeholder="请输入"
            :disabled="true"
        />

        <van-field
            v-model="formInline.submitTime"
            label="问题提交时间"
            placeholder="请输入"
            :disabled="true"
        />

        <textAreaInput :remark="formInline.problemDesc" @change="handleContentChange($event,'problemDesc')"
                       :title="'问题描述'" :disabled="true"/>

        <van-field
            readonly
            clickable
            v-model="formInline.isDealStr"
            label="是否处理"
            placeholder="请选择"
            @click="showPickerIsDeal = true"
            :required="true"
            :rules="[{ required: true, message: '请选择是否处理' }]"
        />
        <van-popup v-model="showPickerIsDeal" position="bottom">
          <van-picker
              show-toolbar
              :columns="isDealOptions"
              value-key="dictLabel"
              @confirm="
                onConfirm($event, 'isDeal', 'isDealStr', 'dictLabel', 'showPickerIsDeal')
              "
              @cancel="showPickerIsDeal = false"
          />
        </van-popup>

        <van-field
            readonly
            clickable
            v-model="formInline.faultTypeStr"
            label="故障类型"
            placeholder="请选择"
            @click="showPickerFaultType = true"
            :required="true"
            :rules="[{ required: true, message: '请选择故障类型' }]"
        />
        <van-popup v-model="showPickerFaultType" position="bottom">
          <van-picker
              show-toolbar
              :columns="faultTypeOptions"
              value-key="dictLabel"
              @confirm="
                onConfirm($event, 'faultType', 'faultTypeStr', 'dictLabel', 'showPickerFaultType')
              "
              @cancel="showPickerFaultType = false"
          />
        </van-popup>

        <textAreaInput :remark="formInline.faultReason" @change="handleContentChange($event,'faultReason')"
                       :title="'故障原因'"/>

        <van-field
            v-model="formInline.solurionPerson"
            label="问题解决人"
            placeholder="请输入"
        />

        <van-field
            readonly
            clickable
            name="calendar"
            :value="formInline.solurionTime"
            label="解决时间"
            placeholder="点击选择日期"
            @click="showSolurionTimePop = true"
        />

        <textAreaInput :remark="formInline.solution" @change="handleContentChange($event,'solution')"
                       :title="'解决办法'"/>

        <textAreaInput :remark="formInline.remark" @change="handleContentChange($event,'remark')"
                       :title="'维修耗材'"/>

        <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="!formInline.isDeal"/>

        <div class="line"></div>
        <van-field name="uploader" label="附件" v-if="isShowAuth">
          <template #input>
            <van-uploader v-model="uploader" :after-read="afterRead"/>
          </template>
        </van-field>

        <van-button class="btn" block type="info" native-type="submit"
                    v-if="isEdit">提交
        </van-button>

      </van-form>
    </div>

    <van-popup v-model="showSolurionTimePop" style="width: 70%">
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="请选择解决时间"
          @confirm="onConfirmTime($event, 'solurionTime', 'showSolurionTimePop')"
      />
    </van-popup>
  </div>
</template>

<script>
import {getMaintenance, updateMaintenance} from "@/api/crm/maintenance";
import {changeToCurrentDay} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";
import AppendixVue from "@/views/components/crm/Appendix.vue";

export default {
  components: {AppendixVue},
  data() {
    return {
      currentDate: new Date(),
      dataId: this.$route.query.id,
      formInline: {},
      uploader: [],
      showPickerIsDeal: false,
      showPickerFaultType: false,
      showSolurionTimePop: false,
      //是否处理
      isDealOptions: [
        {dictLabel: '是', dictValue: true},
        {dictLabel: '否', dictValue: false},
      ],
      //故障类别
      faultTypeOptions: [],
      uploadParams: {
        businessType: 'crm_maintenance',
        businessId: undefined,
        file: null,
      },
      //是否能操作
      isEdit: true,
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    this.getDetail();
    this.getDicts('crm_fault_type').then(response => {
      this.faultTypeOptions = response.data
    })
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/crm/maintenance');
      this.$router.go(-1)
    },

    async getDetail() {
      const result = await getMaintenance(this.dataId);
      const {data = {}} = result;
      this.formInline = data;
      if (this.formInline.isDeal) {
        this.isEdit = false
        this.formInline.isDealStr = '已处理'
      } else {
        this.formInline.isDealStr = '未处理'
      }
    },

    //确认
    onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    onConfirmTime(value, key, pop) {
      const time = changeToCurrentDay(value);
      this.formInline[key] = time;
      this[pop] = false;
    },

    //备注
    handleContentChange(val, key) {
      this.formInline[key] = val;
    },

    async onSubmit() {
      let result = await updateMaintenance(JSON.stringify(this.formInline))
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },

  }

};
</script>

<style lang="scss" scoped>
</style>
